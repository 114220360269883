<template>
    <div class="home container" :class="$vuetify.breakpoint.xsOnly ? 'px-0' : ''">
        <install-pwa-banner-ios
            class="installBanner"
            v-if="$browserDetect.isIOS"
        />
        <install-pwa-banner class="installBanner" v-else />

        <background-parallax
            :backgroundOpacityProp="'0.3'"
            :srcProp="require('@/assets/images/garage.jpg')"
        />
        <div class="mt-12 pt-12" style="position: relative"></div>
        <v-sheet
            class="mb-12 py-12 box box-rounded box-transparent box-transparent-dark"
            elevation="6"
        >
            <h1 class="page-header display-1 font-weight-medium white--text">
                My Garage
            </h1>
        </v-sheet>
        <v-card
            id="garage-card"
            class="box box-rounded box-transparent box-transparent-light"
            elevation="12"
        >
            <v-card-subtitle>
                <v-breadcrumbs class="smc-breadcrumbs">
                    <v-breadcrumbs-item class="smc-breadcrumbs-item" to="/">
                        <v-icon>mdi-home</v-icon>
                    </v-breadcrumbs-item>
                    <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
                    <v-breadcrumbs-item class="smc-breadcrumbs-item">
                        My Garage
                    </v-breadcrumbs-item>
                </v-breadcrumbs>
                <div class="sort-container">
                    Sort by:
                    <v-chip>{{ sortModel.selectedSort.text }}</v-chip>
                    <v-hover v-slot="{ hover }">
                        <v-btn icon @click="invertSort">
                            <v-icon :color="hover ? 'primary' : 'default'">
                                {{
                                    sortModel.dir === "desc"
                                        ? "mdi-arrow-down-thick"
                                        : "mdi-arrow-up-thick"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-hover>
                </div>
            </v-card-subtitle>

            <my-garage-tab />
        </v-card>

        <v-dialog v-model="noVehicleFoundShow" width="550" persistent>
            <v-card>
                <v-toolbar color="primary" dark height="48"> Please note </v-toolbar>
                <v-card-text>
                    <p class="mt-4">
                        The selected vehicle was not found in the currently logged in
                        account
                    </p>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        @click="noVehicleFoundShow = false"
                        rounded
                        class="px-4"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import _ from 'lodash';
import memoize from 'memoizee';

import backgroundParallax from '@/components/widgets/background-parallax';
import installPwaBanner from '@/components/widgets/install-pwa-banner';
import installPwaBannerIos from '@/components/widgets/install-pwa-banner-ios';
import myGarageTab from '@/components/my-garage-tab';

export default {
    name: 'my-garage',
    components: {
        backgroundParallax,
        installPwaBanner,
        installPwaBannerIos,
        myGarageTab,
    },
    props: {
        customerVehicleMappingIdProp: String,
    },
    data: () => ({
        searchQuery: '',
        loading: true,

        currentPage: 1,
        itemsPerPage: 25,

        editVehicleShow: false,
        editVehicleLoading: false,

        noVehicleFoundShow: false,

        vehicles: [],
        sortModel: {
            dir: 'desc',
            selectedSort: { text: 'Date added', value: 'dateCreatedUtc' },
        },
    }),
    computed: {
        searchTokens() {
            if (this.searchQuery) {
                return _.words(_.toLower(_.trim(this.searchQuery)));
            }
            return [];
        },
        vehiclesFiltered() {
            return this.searchQuery ? this.searchVehicles() : this.vehicles;
        },
        vehiclesSorted() {
            return _.orderBy(
                this.vehiclesFiltered,
                [this.sortModel.selectedSort.value],
                [this.sortModel.dir],
            );
        },
        paginatedVehicles() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.vehiclesSorted.slice(startIndex, endIndex);
        },

        totalPages() {
            return Math.ceil(this.vehiclesSorted.length / this.itemsPerPage);
        },

        showPagination() {
            return this.vehiclesSorted.length > this.itemsPerPage;
        },
    },
    created() {
        this.searchVehicles = memoize(this.searchVehicles, {
            normalizer: () => JSON.stringify(this.searchQuery),
        });
    },
    /*
  async mounted() {
    var result = await userService.getVehicles();
    this.vehicles = result.data;
    if (this.customerVehicleMappingIdProp) {
      var vehicleMapping = _.find(this.vehicles, [
        "id",
        parseInt(this.customerVehicleMappingIdProp),
      ]);
      if (vehicleMapping) {
        this.$nextTick(() => {
          this.$refs[
            `customerVehicle${vehicleMapping.id}`
          ][0].captureVehicleMileage();
        });
      } else {
        this.noVehicleFoundShow = true;
      }
    }
    this.loading = false;
  },
  */
    watch: {
        searchQuery() {
            this.currentPage = 1;
        },
    },
    methods: {
        searchVehicles() {
            const searchTokens = new Set(this.searchTokens);

            return this.vehicles.filter(vehicle => {
                const { name, vin } = vehicle;
                const {
                    year,
                    brand,
                    range,
                    model,
                    fuelType,
                    transmissionType,
                    gearboxType,
                } = vehicle.vehicle;

                const fieldsToSearch = [
                    name,
                    vin,
                    brand,
                    range,
                    model,
                    year,
                    transmissionType,
                    fuelType,
                    gearboxType,
                ];

                const matchedTokens = Array.from(searchTokens).filter(token =>
                    fieldsToSearch.some(field =>
                        _.toLower(_.toString(field)).includes(token),
                    ),
                );

                return matchedTokens.length === searchTokens.size;
            });
        },
        invertSort() {
            if (this.sortModel.dir === 'asc') {
                this.sortModel.dir = 'desc';
            }
            else {
                this.sortModel.dir = 'asc';
            }
        },
        updateVehicleMapping(vehicleMapping) {
            this.editVehicleShow = false;
            let index = _.findIndex(this.vehicles, ['id', vehicleMapping.id]);
            if (index !== -1) {
                this.vehicles.splice(index, 1, vehicleMapping);
                this.vehicles = [...this.vehicles];
            }
            else {
                this.vehicles.push(vehicleMapping);
            }
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.$nextTick(() => {
                this.$vuetify.goTo('#garage-card');
            });
        },
    },
};
</script>

<style scoped>
@import "../assets/scss/vuetify-elements/card.scss";
@import "../assets/scss/vuetify-elements/sheet.scss";

.page-header {
  position: relative;
}

.smc-breadcrumbs {
  padding-left: 12px;
}

.smc-breadcrumbs .smc-breadcrumbs-item {
  font-weight: 600;
}
</style>

<style lang="scss" scoped>
.sort-container {
  text-align: right;

  @media screen and (min-width: 601px) {
    position: absolute;
    right: 16px;
    top: 26px;
  }
}
</style>
