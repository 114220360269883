<template>
    <v-container class="position-relative" v-if="pwaInstallEvent">
        <v-banner
            color="info"
            class="pwa-banner text-left white--text"
            elevation="10"
        >
            Install our app for easy access in the future
            {{ event }}
            <template #actions>
                <v-btn class="white--text" outlined text @click="dismiss">
                    Dismiss
                </v-btn>
                <v-btn class="white--text" outlined text @click="install">
                    Install
                </v-btn>
            </template>
        </v-banner>
    </v-container>
</template>

<script>
export default {
    name: 'install-pwa-banner',
    computed: {
        pwaInstallEvent() {
            return this.$store.getters['pwaInstall/getInstallEvent'];
        },
    },
    methods: {
        dismiss() {
            if (process.env.NODE_ENV === 'production') {
                this.$cookies.set('pwa_install_dismiss', 'dismiss', '15d');
            }
            else {
                this.$cookies.set('pwa_install_dismiss', 'dismiss', '1d');
            }
            this.$store.dispatch('pwaInstall/clearInstallEvent');
        },
        install() {
            this.pwaInstallEvent.prompt();
        },
    },
};
</script>

<style scoped>
.pwa-banner {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  border-radius: 15px;
}
</style>
