<template>
    <v-card width="700" class="mt-12 mx-auto smc-card-dialog">
        <v-card-title
            class="primary white--text text-center smc-card-dialog-title smc-card-dialog-title-narrow"
        >
            <span class="mx-auto">My profile</span>
        </v-card-title>
        <v-card-text>
            <ValidationObserver ref="updateFormValidationObserver">
                <v-expand-transition>
                    <v-form
                        v-model="updateValid"
                        v-if="!updatePasswordMode"
                        ref="updateForm"
                        lazy-validation
                        @submit="submitUpdate($event)"
                    >
                        <v-row>
                            <v-col cols="12" sm="6" class="label-col">
                                <label class="v-label profile-label font-weight-bold">
                                    First name
                                </label>
                            </v-col>
                            <v-col>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    name="First name"
                                    rules="required"
                                >
                                    <v-text-field
                                        id="update-firstName"
                                        v-model="user.firstName"
                                        :error-messages="errors"
                                        prepend-inner-icon="mdi-account-edit"
                                        :outlined="isEditMode"
                                        :rounded="isEditMode"
                                        :readonly="!isEditMode"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" class="label-col">
                                <label class="v-label profile-label font-weight-bold">
                                    Last name
                                </label>
                            </v-col>
                            <v-col>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Last name"
                                    rules="required"
                                >
                                    <v-text-field
                                        id="update-lastName"
                                        v-model="user.lastName"
                                        :error-messages="errors"
                                        prepend-inner-icon="mdi-account-edit"
                                        :outlined="isEditMode"
                                        :rounded="isEditMode"
                                        :readonly="!isEditMode"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" class="label-col">
                                <label class="v-label profile-label font-weight-bold">
                                    Email address
                                </label>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    id="update-emailAddress"
                                    prepend-inner-icon="mdi-email"
                                    readonly
                                    v-model="user.email"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" class="label-col">
                                <label class="v-label profile-label font-weight-bold">
                                    Phone number
                                </label>
                            </v-col>
                            <v-col>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Mobile number"
                                    rules="required|phone"
                                >
                                    <v-text-field
                                        id="update-phoneNumber"
                                        v-model="user.phoneWithoutPrefix"
                                        :error-messages="errors"
                                        prepend-inner-icon="mdi-cellphone"
                                        v-mask="'### ### ####'"
                                        :outlined="isEditMode"
                                        :rounded="isEditMode"
                                        :readonly="!isEditMode"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                        <v-row v-if="!isOrganization">
                            <v-col cols="12" sm="6" class="label-col">
                                <label
                                    class="v-label profile-label font-weight-bold"
                                    for="update-isBusiness"
                                >
                                    Is business
                                </label>
                            </v-col>
                            <v-col>
                                <v-switch
                                    id="update-isBusiness"
                                    v-model="isBusiness"
                                    :disabled="!isEditMode"
                                />
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <v-card
                                elevation="4"
                                class="smc-card-dialog mb-6"
                                v-if="isOrganization"
                            >
                                <v-card-title
                                    class="smc-card-dialog-title-inset primary white--text py-1"
                                >
                                    <span class="mx-auto">Organization</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6" class="label-col">
                                            <label class="v-label profile-label font-weight-bold">
                                                Organization name
                                            </label>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                :value="organization.name"
                                                prepend-inner-icon="mdi-domain"
                                                hide-details
                                                readonly
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="organization.registrationNumber">
                                        <v-col cols="12" sm="6" class="label-col">
                                            <label class="v-label profile-label font-weight-bold">
                                                Registration number
                                            </label>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                :value="organization.registrationNumber"
                                                prepend-inner-icon="mdi-domain"
                                                hide-details
                                                readonly
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="organization.taxNumber">
                                        <v-col cols="12" sm="6" class="label-col">
                                            <label class="v-label profile-label font-weight-bold">
                                                Tax number
                                            </label>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                :value="organization.taxNumber"
                                                prepend-inner-icon="mdi-domain"
                                                hide-details
                                                readonly
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-card
                                        outlined
                                        elevation="4"
                                        class="smc-card-dialog mt-6 mb-2"
                                        v-for="branch in organization.organizationBranches"
                                        :key="branch.id"
                                    >
                                        <v-card-title
                                            class="smc-card-dialog-title-inset primary white--text py-1"
                                        >
                                            <span class="mx-auto">Branch</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12" sm="6" class="label-col">
                                                    <label class="v-label profile-label font-weight-bold">
                                                        Branch name
                                                    </label>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field
                                                        :value="branch.branchName"
                                                        prepend-inner-icon="mdi-domain"
                                                        readonly
                                                        hide-details
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="branch.address">
                                                <v-col cols="12" sm="6" class="label-col">
                                                    <label class="v-label profile-label font-weight-bold">
                                                        Branch address
                                                    </label>
                                                </v-col>
                                                <v-col>
                                                    <v-textarea
                                                        prepend-inner-icon="mdi-google-maps"
                                                        readonly
                                                        rows="7"
                                                        no-resize
                                                        :value="
                                                            addressHelper.paragraphyAddress(branch.address)
                                                        "
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-card-text>
                            </v-card>
                            <v-card class="smc-card-dialog mb-6" v-else-if="isBusiness">
                                <v-card-title
                                    class="smc-card-dialog-title-inset primary white--text py-1"
                                >
                                    <span class="mx-auto">Business details</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6" class="label-col">
                                            <label class="v-label profile-label font-weight-bold">
                                                Business name
                                            </label>
                                        </v-col>
                                        <v-col>
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Business name"
                                                :rules="isBusiness ? 'required' : ''"
                                            >
                                                <v-text-field
                                                    id="update-businessName"
                                                    v-model="user.businessName"
                                                    :error-messages="errors"
                                                    prepend-inner-icon="mdi-domain"
                                                    :outlined="isEditMode"
                                                    :rounded="isEditMode"
                                                    :readonly="!isEditMode"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6" class="label-col">
                                            <label class="v-label profile-label font-weight-bold">
                                                Business registration number
                                            </label>
                                        </v-col>
                                        <v-col>
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Business registration number"
                                            >
                                                <v-text-field
                                                    id="update-businessRegistrationNumber"
                                                    v-model="user.businessRegistrationNumber"
                                                    :error-messages="errors"
                                                    prepend-inner-icon="mdi-domain"
                                                    :outlined="isEditMode"
                                                    :rounded="isEditMode"
                                                    :readonly="!isEditMode"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6" class="label-col">
                                            <label class="v-label profile-label font-weight-bold">
                                                Business VAT number
                                            </label>
                                        </v-col>
                                        <v-col>
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Business VAT number"
                                            >
                                                <v-text-field
                                                    id="update-businessTaxNumber"
                                                    v-model="user.businessTaxNumber"
                                                    :error-messages="errors"
                                                    prepend-inner-icon="mdi-domain"
                                                    :outlined="isEditMode"
                                                    :rounded="isEditMode"
                                                    :readonly="!isEditMode"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="isEditMode">
                                        <v-col cols="12">
                                            <address-capture
                                                :addressProp="businessAddress"
                                                :allowManualCaptureProp="true"
                                                :completeAddressRequiredProp="true"
                                                @addressChange="updateAddress($event)"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" sm="6" class="label-col">
                                            <label class="v-label profile-label font-weight-bold">
                                                Business address
                                            </label>
                                        </v-col>
                                        <v-col>
                                            <v-textarea
                                                id="update-businessAddress"
                                                prepend-inner-icon="mdi-google-maps"
                                                readonly
                                                rows="7"
                                                outlined
                                                no-resize
                                                :value="
                                                    addressHelper.paragraphyAddress(this.businessAddress)
                                                "
                                            />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-expand-transition>
                        <v-divider />
                        <v-expand-transition>
                            <v-row v-if="errorMessage">
                                <v-col>
                                    <span class="error-label error--text pa-3">
                                        {{ errorMessage }}
                                    </span>
                                </v-col>
                            </v-row>
                        </v-expand-transition>
                        <v-divider v-if="errorMessage" />
                        <v-card-actions :class="isEditMode ? 'pa-0' : ''">
                            <v-expand-transition>
                                <v-row v-if="!isEditMode">
                                    <v-col>
                                        <v-btn
                                            rounded
                                            outlined
                                            color="primary"
                                            @click="isEditMode = true"
                                        >
                                            Edit details
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-btn
                                            rounded
                                            outlined
                                            color="primary"
                                            @click="updatePasswordMode = true"
                                        >
                                            Change password
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-expand-transition>
                        </v-card-actions>
                        <v-card-actions :class="!isEditMode ? 'pa-0' : ''">
                            <v-expand-transition>
                                <v-row v-if="isEditMode">
                                    <v-col>
                                        <v-btn
                                            rounded
                                            color="primary"
                                            :loading="loading"
                                            class="smc-medium-button"
                                            type="submit"
                                        >
                                            Submit
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-btn
                                            rounded
                                            outlined
                                            color="secondary"
                                            class="smc-medium-button"
                                            :disabled="loading"
                                            @click="cancelUpdate"
                                        >
                                            Cancel
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-expand-transition>
                        </v-card-actions>
                    </v-form>
                </v-expand-transition>
            </ValidationObserver>

            <ValidationObserver ref="updatePasswordValidationObserver">
                <v-expand-transition>
                    <v-form
                        v-model="passwordValid"
                        v-if="updatePasswordMode"
                        ref="updatePassword"
                        lazy-validation
                        @submit="submitPassword($event)"
                    >
                        <v-row>
                            <v-col cols="12" sm="6" class="label-col">
                                <label class="v-label profile-label font-weight-bold">
                                    Current password
                                </label>
                            </v-col>
                            <v-col>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Password"
                                    rules="required"
                                >
                                    <v-text-field
                                        id="update-currentPassword"
                                        v-model="currentPassword"
                                        :error-messages="errors"
                                        prepend-inner-icon="mdi-lock"
                                        :append-icon="
                                            currentPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'
                                        "
                                        :type="currentPasswordVisible ? 'text' : 'password'"
                                        name="current-password"
                                        @click:append="
                                            currentPasswordVisible = !currentPasswordVisible
                                        "
                                        rounded
                                        outlined
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" class="label-col">
                                <label class="v-label profile-label font-weight-bold">
                                    New password
                                </label>
                            </v-col>
                            <v-col>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    name="New Password"
                                    vid="newPassword"
                                    rules="required|password|min:8"
                                >
                                    <v-text-field
                                        id="update-newPassword"
                                        v-model="newPassword"
                                        :error-messages="errors"
                                        prepend-inner-icon="mdi-lock"
                                        :append-icon="
                                            newPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'
                                        "
                                        :type="newPasswordVisible ? 'text' : 'password'"
                                        name="current-password"
                                        hint="At least 8 characters"
                                        @click:append="newPasswordVisible = !newPasswordVisible"
                                        rounded
                                        outlined
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" class="label-col">
                                <label class="v-label profile-label font-weight-bold">
                                    Confirm new password
                                </label>
                            </v-col>
                            <v-col>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Confirm Password"
                                    rules="required|confirmedBy:@newPassword"
                                >
                                    <v-text-field
                                        id="update-confirmPassword"
                                        v-model="confirmNewPassword"
                                        :error-messages="errors"
                                        prepend-inner-icon="mdi-lock"
                                        :append-icon="
                                            newPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'
                                        "
                                        :type="newPasswordVisible ? 'text' : 'password'"
                                        name="current-password"
                                        @click:append="newPasswordVisible = !newPasswordVisible"
                                        rounded
                                        outlined
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                        <v-divider />
                        <v-expand-transition>
                            <v-row v-if="errorMessage" class="py-3">
                                <v-col>
                                    <span class="error-label error--text" v-html="errorMessage">
                                    </span>
                                </v-col>
                            </v-row>
                        </v-expand-transition>
                        <v-divider v-if="errorMessage" />
                        <v-card-actions>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        rounded
                                        color="primary"
                                        :loading="loading"
                                        class="smc-medium-button"
                                        type="submit"
                                    >
                                        Submit
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        rounded
                                        outlined
                                        color="secondary"
                                        class="smc-medium-button"
                                        :disabled="loading"
                                        @click="cancelPassword"
                                    >
                                        Cancel
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-form>
                </v-expand-transition>
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>

<script>
import addressCapture from '@/components/address-capture.vue';
import { authService, userService } from '@/services';
import { addressHelper, userHelper } from '@/helpers';
import validate from '@/mixins/validator';
import { mask } from 'vue-the-mask';
import _ from 'lodash';

export default {
    name: 'account',
    directives: { mask },
    components: {
        addressCapture,
    },
    mixins: [validate],
    data: () => ({
        errorMessage: '',
        loading: false,
        addressHelper,

        // user
        updateValid: false,
        isEditMode: false,
        user: {},
        isBusiness: false,
        businessAddress: addressHelper.getEmptyAddress(),

        // password
        passwordValid: false,
        updatePasswordMode: false,
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        currentPasswordVisible: false,
        newPasswordVisible: false,
    }),
    computed: {
        storeUser: function () {
            return this.$store.getters['user/getUserModel'];
        },
        isOrganization() {
            return this.$store.getters['user/isOrganization'];
        },
        organization() {
            return this.$store.getters['user/getOrganization'];
        },
    },
    async created() {
        if (!this.$store.getters['user/isLoaded']) {
            await this.$store.dispatch('user/loadUser');
        }

        this.user = _.clone(this.storeUser);
        if (this.user.businessName) {
            this.isBusiness = true;
            this.businessAddress = this.user.businessAddress
                ? this.user.businessAddress
                : this.businessAddress;
        }
    },
    methods: {
        cancelUpdate() {
            this.user = _.clone(this.storeUser);
            if (this.user.businessName) {
                this.isBusiness = true;
            }
            else {
                this.isBusiness = false;
            }
            this.errorMessage = '';
            this.isEditMode = false;
        },
        cancelPassword() {
            this.currentPassword = '';
            this.newPassword = '';
            this.confirmNewPassword = '';
            this.errorMessage = '';
            this.isEditMode = false;
            this.updatePasswordMode = false;
        },
        updateAddress(address) {
            this.businessAddress = address;
        },
        async submitUpdate($event) {
            $event.preventDefault();
            this.errorMessage = '';
            if (await this.$refs.updateFormValidationObserver.validate()) {
                this.loading = true;

                if (this.user.phoneWithoutPrefix) {
                    this.user.phone = userHelper.addPhonePrefix(
                        this.user.phoneWithoutPrefix,
                    );
                }

                if (this.isBusiness) {
                    this.businessAddress.id = 0;
                    this.user.businessAddress = this.businessAddress;
                    if (!this.user.businessRegistrationNumber) {
                        this.user.businessRegistrationNumber = '';
                    }
                    if (!this.user.businessTaxNumber) {
                        this.user.businessTaxNumber = '';
                    }
                }
                else {
                    this.user.businessName = '';
                    this.user.businessRegistrationNumber = '';
                    this.user.businessTaxNumber = '';
                    this.user.businessAddress = null;
                }

                let result = await userService.upsertUser(this.user);
                if (result.status === 200) {
                    await this.$store.dispatch('user/loadUser');
                    this.user = _.clone(this.storeUser);

                    this.isEditMode = false;
                }
                else {
                    let validationMessages = [];
                    _.mapKeys(result.data, function (value) {
                        validationMessages.push(`${value}`);
                    });

                    this.errorMessage = validationMessages;
                }
            }

            if (!this.isBusiness) {
                this.businessAddress = {};
            }

            this.loading = false;
        },
        async submitPassword($event) {
            $event.preventDefault();
            this.errorMessage = '';
            if (await this.$refs.updatePasswordValidationObserver.validate()) {
                this.loading = true;

                let result = await authService.changePassword({
                    oldPassword: this.currentPassword,
                    newPassword: this.newPassword,
                });

                if (result.status === 200) {
                    this.currentPassword = '';
                    this.newPassword = '';
                    this.confirmNewPassword = '';
                    this.isEditMode = false;
                    this.updatePasswordMode = false;
                }
                else {
                    if (result.data.User) {
                        let validationMessages = [];
                        _.mapKeys(result.data.User, function (value) {
                            validationMessages.push(`<span>${value}</span>`);
                        });

                        this.errorMessage = `<div>${_.map(validationMessages).join(
                            '',
                        )}</div>`;
                    }
                    else {
                        this.errorMessage = 'Something went wrong.';
                    }
                }
            }
            this.loading = false;
        },
    },
};
</script>

<style scoped>
.profile-label {
  line-height: 60px;
}

.label-col {
  height: 48px;
}
</style>
