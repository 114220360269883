<template>
    <v-card width="550" class="mx-auto smc-card-dialog">
        <v-card-title
            class="
        primary
        white--text
        text-center
        smc-card-dialog-title smc-card-dialog-title-narrow
      "
        >
            <span class="mx-auto">RESET</span>
        </v-card-title>
        <v-card-text>
            <ValidationObserver ref="validationObserver">
                <v-form
                    v-model="valid"
                    ref="form"
                    lazy-validation
                    @submit="onSubmit($event)"
                >
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Email address"
                        rules="required|email"
                    >
                        <v-text-field
                            id="forgotPassword-email"
                            v-model="username"
                            :error-messages="errors"
                            prepend-inner-icon="mdi-account-circle"
                            :readonly="resetSent"
                            label="Email Address"
                            rounded
                            outlined
                        />
                    </ValidationProvider>
                    <v-divider />
                    <v-expand-transition>
                        <v-row v-if="errorMessage">
                            <v-col>
                                <span class="error-label error--text pa-3">
                                    {{ errorMessage }}
                                </span>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                    <v-divider v-if="errorMessage" />
                    <v-alert v-if="resetSent" border="left" color="success" outlined rounded>
                        Your password reset email has been sent.
                        <br />
                        Please follow the instructions in the email to continue.
                    </v-alert>
                    <v-card-actions>
                        <v-row>
                            <v-col>
                                <v-btn
                                    id="forgotPassword-submit"
                                    :disabled="resetSent"
                                    color="primary"
                                    class="mx-auto smc-medium-button"
                                    rounded
                                    type="submit"
                                    :loading="loading"
                                >
                                    Submit
                                </v-btn>
                            </v-col>
                            <v-spacer class="d-none f-sm-flex" />
                            <v-col>
                                <v-btn
                                    id="forgotPassword-login"
                                    class="mx-auto"
                                    color="darkGrey"
                                    rounded
                                    outlined
                                    :to="`/account/login${
                                        returnUrlProp ? '?returnUrl=' + returnUrlProp : ''
                                    }`"
                                >
                                    Back to login
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-form>
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>

<script>
import { authService } from '@/services';
import validate from '@/mixins/validator';
import _ from 'lodash';

export default {
    name: 'forgot-password',
    mixins: [validate],
    data() {
        return {
            valid: false,
            loading: false,
            resetSent: false,
            errorMessage: '',
            username: '',
        };
    },
    props: {
        returnUrlProp: String,
    },
    watch: {
        username: function () {
            this.username = _.trim(this.username);
        },
    },
    methods: {
        async onSubmit(event) {
            event.preventDefault();
            if (await this.$refs.validationObserver.validate()) {
                this.loading = true;
                let result = await authService.forgotPassword(this.username);

                if (result.status === 200) {
                    this.resetSent = true;
                }

                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
@import "../../assets/scss/vuetify-elements/card.scss";

.smc-card-dialog {
  margin-top: 100px;
}
</style>
