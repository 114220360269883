<template>
    <v-card width="550" class="mx-auto smc-card-dialog">
        <v-card-title
            class="primary white--text text-center smc-card-dialog-title smc-card-dialog-title-narrow"
        >
            <span class="mx-auto">LOGIN</span>
        </v-card-title>
        <v-card-text>
            <ValidationObserver ref="validationObserver">
                <v-form
                    v-model="valid"
                    ref="form"
                    lazy-validation
                    @submit="submit($event)"
                >
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Email address"
                        rules="required|email"
                    >
                        <v-text-field
                            id="login-email"
                            v-model="username"
                            :error-messages="errors"
                            prepend-inner-icon="mdi-account-circle"
                            label="Email Address"
                            outlined
                            rounded
                        />
                    </ValidationProvider>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Password"
                        rules="required"
                    >
                        <v-text-field
                            id="login-password"
                            v-model="password"
                            :error-messages="errors"
                            prepend-inner-icon="mdi-lock"
                            :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="passwordVisible ? 'text' : 'password'"
                            name="password"
                            label="Password"
                            outlined
                            rounded
                            @click:append="passwordVisible = !passwordVisible"
                        />
                    </ValidationProvider>
                    <v-divider />
                    <v-expand-transition>
                        <v-row v-if="errorMessage" class="py-3">
                            <v-col>
                                <span class="error-label error--text">
                                    {{ errorMessage }}
                                </span>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                    <v-divider v-if="errorMessage" />
                    <v-card-actions>
                        <v-row>
                            <v-col>
                                <v-btn
                                    id="login-submit"
                                    :disabled="!valid"
                                    color="primary"
                                    type="submit"
                                    rounded
                                    :loading="loading"
                                    class="smc-medium-button mt-2"
                                >
                                    Login
                                </v-btn>
                            </v-col>
                            <v-spacer class="d-none d-sm-flex" />
                            <v-col>
                                <v-btn
                                    id="login-signinGoogle"
                                    color="info"
                                    @click="signinGoogle"
                                    text
                                    height="50"
                                    class="smc-text-button pa-0"
                                >
                                    <v-img
                                        width="200"
                                        src="@/assets/images/google_signin_buttons/web/2x/btn_google_signin_light_normal_web@2x.png"
                                        lazy-src="@/assets/images/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png"
                                    />
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12" class="mx-auto py-1">
                                Having trouble loggin in?
                                <v-btn
                                    id="login-forgotPassword"
                                    text
                                    rounded
                                    :to="`/account/forgot-password${
                                        returnUrlProp ? '?returnUrl=' + returnUrlProp : ''
                                    }`"
                                    class="primary--text text-none smc-text-button"
                                >
                                    Forgot password
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="mx-auto py-1">
                                No account?
                                <v-btn
                                    id="login-register"
                                    text
                                    rounded
                                    to="/account/signup"
                                    class="primary--text text-none smc-text-button"
                                >
                                    Register
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-form>
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>

<script>
import _ from 'lodash';
import { authService } from '@/services';
import { authStatuses } from '@/helpers';
import validate from '@/mixins/validator';
import AuthenticateLogin from '@/models/authenticate-login';

export default {
    name: 'login',
    mixins: [validate],
    data() {
        return {
            valid: false,
            loading: false,
            errorMessage: '',
            username: '',
            password: '',
            passwordVisible: false,
        };
    },
    props: {
        returnUrlProp: String,
    },
    watch: {
        username: function () {
            this.username = _.trim(this.username);
        },
    },
    methods: {
        async submit($event) {
            $event.preventDefault();

            if (await this.$refs.validationObserver.validate()) {
                this.loading = true;
                this.passwordVisible = false;

                let result = await authService.authenticate(
                    new AuthenticateLogin(
                        _.trim(this.username),
                        _.trim(this.username),
                        this.password,
                    ),
                );

                if (result.status === 200 || result.status === 401) {
                    switch (result.data.status) {
                        case authStatuses.success:
                            this.$store.dispatch('user/setAuthUserId', result.data.authUserId);
                            this.$store.dispatch('user/setUser', result.data.applicationUser);
                            this.$store.dispatch('user/setInnovationPolicy', {
                                hasInnovationPolicy: result.data.hasInnovationPolicy,
                                innovationPolicyList: result.data.innovationPolicyList,
                            });
                            if (result.data.organization) {
                                this.$store.dispatch(
                                    'user/setOrganization',
                                    result.data.organization,
                                );
                                this.$store.dispatch(
                                    'user/setOrganizationBranchOptions',
                                    result.data.organizationBranchOptions,
                                );
                            }

                            this.$store.dispatch('user/setToken', {
                                accessToken: {
                                    token: result.data.accessToken,
                                    tokenExpiresUtc: result.data.accessTokenExpiresUtc,
                                    tokenExpires: result.data.accessTokenExpires,
                                },
                                refreshToken: {
                                    token: result.data.refreshToken,
                                    tokenExpiresUtc: result.data.refreshTokenExpiresUtc,
                                    tokenExpires: result.data.refreshTokenExpires,
                                },
                            });

                            if (this.returnUrlProp) {
                                this.$router.push(this.returnUrlProp);
                            }
                            else if (result.data.organization) {
                                this.$router.push({
                                    name: 'active-requests',
                                });
                            }
                            else {
                                this.$router.push({
                                    name: 'my-garage',
                                });
                            }
                            break;
                        case authStatuses.failed:
                            this.errorMessage = 'Login failed.';
                            this.loading = false;
                            break;
                        case authStatuses.emailConfirmationRequired:
                            this.errorMessage = 'Unable to log in. Please check your email for more details.';
                            this.loading = false;
                            break;
                        case authStatuses.newPasswordRequired:
                            this.passwordRequired = true;
                            this.loading = false;
                            break;
                        case authStatuses.lockedOut:
                            this.errorMessage = 'Account locked. Please try again in a few minutes.';
                            this.loading = false;
                            break;
                        case authStatuses.notAllowed:
                            this.errorMessage = 'Login failed.';
                            this.loading = false;
                            break;
                        case authStatuses.requiresTwoFactor:
                    }
                }
                else {
                    this.errorMessage = 'Something went wrong.';
                }
            }
            this.loading = false;
        },
        async signinGoogle() {
            //await this.$gAuth.signIn();
            const googleUser = await this.$gAuth.signIn();
            if (!googleUser) {
                return null;
            }
            let authResponse = googleUser.getAuthResponse();
            //let email = googleUser.getBasicProfile().getEmail();

            this.loading = true;
            let result = await authService.externalAuthenticate({
                idToken: authResponse.id_token,
                provider: 'google',
            });

            if (result.status === 200 && result.data.status === authStatuses.success) {
                this.$store.dispatch('user/setUser', result.data.applicationUser);
                this.$store.dispatch('user/setInnovationPolicy', {
                    hasInnovationPolicy: result.data.hasInnovationPolicy,
                    innovationPolicyList: result.data.innovationPolicyList,
                });

                if (result.data.organization) {
                    this.$store.dispatch(
                        'user/setOrganization',
                        result.data.organization,
                    );
                }

                this.$store.dispatch('user/setToken', {
                    accessToken: {
                        token: result.data.accessToken,
                        tokenExpiresUtc: result.data.accessTokenExpiresUtc,
                        tokenExpires: result.data.accessTokenExpires,
                    },
                    refreshToken: {
                        token: result.data.refreshToken,
                        tokenExpiresUtc: result.data.refreshTokenExpiresUtc,
                        tokenExpires: result.data.refreshTokenExpires,
                    },
                });

                if (this.returnUrlProp) {
                    this.$router.push(this.returnUrlProp);
                }
                else if (result.data.organization) {
                    this.$router.push({
                        name: 'active-requests',
                    });
                }
                else {
                    this.$router.push({
                        name: 'my-garage',
                    });
                }
            }
            else {
                this.errorMessage = 'Something went wrong.';
            }
            this.loading = false;
        },
        async loadGoogleUser(count, idToken) {
            let isLoaded = this.$gAuth.isLoaded();
            if (!isLoaded) {
                setTimeout(async () => {
                    if (count < 15) {
                        await this.loadGoogleUser(count + 1, idToken);
                    }
                }, 350);
            }
            else {
                let result = await authService.externalAuthenticate({
                    idToken: idToken,
                    provider: 'google',
                });

                let email = this.$gAuth.GoogleAuth.currentUser.yb.tv.fw;

                if (
                    result.status === 200
                    && result.data.status === authStatuses.success
                ) {
                    this.$store.dispatch('user/setUser', {
                        username: email,
                        email: email,
                        isLoaded: false,
                    });
                    this.$store.dispatch('user/setToken', {
                        accessToken: {
                            token: result.data.accessToken,
                            tokenExpiresUtc: result.data.accessTokenExpiresUtc,
                            tokenExpires: result.data.accessTokenExpires,
                        },
                        refreshToken: {
                            token: result.data.refreshToken,
                            tokenExpiresUtc: result.data.refreshTokenExpiresUtc,
                            tokenExpires: result.data.refreshTokenExpires,
                        },
                    });

                    if (this.returnUrlProp) {
                        this.$router.push(this.returnUrlProp);
                    }
                    else {
                        this.$router.push({
                            name: 'my-garage',
                        });
                    }
                }
                else {
                    this.errorMessage = 'Something went wrong.';
                }
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
@import "../../assets/scss/vuetify-elements/card.scss";

.smc-card-dialog {
  margin-top: 100px;
}
</style>
