<template>
    <v-card color="white" class="smc-card-dialog mt-4">
        <v-card-title class="smc-card-dialog-title-inset primary white--text">
            <span class="mx-auto"> VEHICLE </span>
        </v-card-title>
        <v-card-text class="pa-4">
            <v-row>
                <v-col>
                    <v-avatar class="ma-3" size="125" width="90%" tile>
                        <img :src="vehicleProp.imageUrl" class="smc-search-result-image" />
                    </v-avatar>
                </v-col>
                <v-col class="text-left">
                    <strong>{{ vehicleProp.year }}</strong>
                    <br />
                    <strong>{{ vehicleProp.brand }}</strong>
                    <br />
                    <strong>{{ vehicleProp.range }}</strong>
                    <br />
                    <strong>{{ vehicleProp.model }}</strong>
                    <br />
                    <strong>{{ vehicleProp.fuelType }}</strong>
                    <br />
                    <strong>{{ vehicleProp.transmissionType }}</strong>
                    <br />
                    <strong>{{ vehicleProp.gearboxType }}</strong>
                    <br />
                </v-col>
                <v-divider vertical />
                <v-col class="text-left" v-if="policyProp">
                    <strong>{{ policyProp.policyCode }}</strong>
                    <br />
                    <strong>{{ policyProp.vinNumber }}</strong>
                    <br />
                    <strong>
                        Start date:
                        {{ dateHelper.formatDateShort(policyProp.startDate) }}
                    </strong>
                    <br />
                    <strong>
                        End date:
                        {{ dateHelper.formatDateShort(policyProp.endDate) }}
                    </strong>
                    <br />
                    <strong> End distance: {{ policyProp.endDistance }} km</strong>
                    <router-link to="#">
                        <v-img
                            class="innovation-logo mt-5"
                            width="160"
                            contain
                            :src="require('@/assets/images/ig-logo.png')"
                            v-ripple
                        />
                    </router-link>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { dateHelper } from '@/helpers';

export default {
    name: 'vehicle-summary',
    props: {
        vehicleProp: Object,
        policyProp: Object,
    },
    data: () => ({
        dateHelper,
    }),
};
</script>

<style lang="scss" scoped>
.smc-card-avatar {
  @media screen and (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.smc-card-dialog.v-card > .v-card__title.smc-card-dialog-title-inset {
  width: 250px;
  padding: 10px;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 32px !important;

  @media screen and (max-width: 600px) {
    width: 100%;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 30px !important;
  }
}

.smc-card-dialog.v-card > .v-card__subtitle.smc-card-dialog-subtitle-inset {
  @media screen and (max-width: 600px) {
    width: 100%;
    position: relative;
    border-bottom-right-radius: 32px;
  }
}
</style>
