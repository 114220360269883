<template>
    <v-card width="550" class="mx-auto smc-card-dialog">
        <v-card-title
            class="
        primary
        white--text
        text-center
        smc-card-dialog-title smc-card-dialog-title-narrow
      "
        >
            <span class="mx-auto">RESET</span>
        </v-card-title>
        <v-card-text>
            <ValidationObserver ref="validationObserver">
                <v-form
                    v-model="valid"
                    ref="form"
                    lazy-validation
                    @submit="onSubmit($event)"
                >
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Email address"
                        rules="required|email"
                    >
                        <v-text-field
                            id="forgotPassword-email"
                            v-model="username"
                            :error-messages="errors"
                            prepend-inner-icon="mdi-account-circle"
                            readonly
                            :disabled="passwordUpdated"
                            label="Email Address"
                            rounded
                            outlined
                        />
                    </ValidationProvider>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Password"
                        rules="required|password|min:8"
                    >
                        <v-text-field
                            id="forgotPasswordConfirm-password"
                            v-model="newPassword"
                            :error-messages="errors"
                            prepend-inner-icon="mdi-lock"
                            :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="passwordVisible ? 'text' : 'password'"
                            name="password"
                            label="Password"
                            hint="At least 8 characters"
                            @click:append="passwordVisible = !passwordVisible"
                            :disabled="passwordUpdated"
                            rounded
                            outlined
                        />
                    </ValidationProvider>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Confirm Password"
                        rules="required|confirmedBy:@Password"
                    >
                        <v-text-field
                            id="forgotPasswordConfirm-confirmPassword"
                            v-model="confirmPassword"
                            :error-messages="errors"
                            prepend-inner-icon="mdi-lock-reset"
                            :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="passwordVisible ? 'text' : 'password'"
                            name="confirmPassword"
                            label="Confirm Password"
                            hint="Password and confirm password must match"
                            @click:append="passwordVisible !== passwordVisible"
                            :disabled="passwordUpdated"
                            rounded
                            outlined
                        />
                    </ValidationProvider>
                    <v-alert v-if="passwordUpdated" color="success" outlined>
                        Your password has been updated.
                        <br />
                        You can now return to the login page.
                    </v-alert>
                    <v-divider />
                    <v-expand-transition>
                        <v-row v-if="errorMessage">
                            <v-col>
                                <span
                                    class="error-label error--text pa-3"
                                    v-html="errorMessage"
                                >
                                </span>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                    <v-divider v-if="errorMessage" />
                    <v-card-actions>
                        <v-row>
                            <v-col>
                                <v-btn
                                    id="forgotPassword-submit"
                                    :disabled="!valid || passwordUpdated"
                                    color="primary"
                                    class="mx-auto smc-medium-button"
                                    rounded
                                    type="submit"
                                    :loading="loading"
                                >
                                    Submit
                                </v-btn>
                            </v-col>
                            <v-spacer class="d-none f-sm-flex" />
                            <v-col>
                                <v-btn
                                    id="forgotPassword-login"
                                    class="mx-auto"
                                    color="darkGrey"
                                    rounded
                                    outlined
                                    to="/account/login"
                                >
                                    Back to login
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-form>
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>

<script>
import _ from 'lodash';
import { authService } from '@/services';
import AuthenticateForgotPasswordConfirm from '@/models/authenticate-forgotPasswordConfirm';
import validate from '@/mixins/validator';

export default {
    name: 'forgot-passaword-confirm',
    mixins: [validate],
    props: {
        emailAddressProp: String,
        codeProp: String,
    },
    data: () => ({
        valid: false,
        loading: false,
        passwordUpdated: false,
        errorState: false,
        errorMessage: '',
        newPassword: '',
        confirmPassword: '',
        passwordVisible: false,
        username: '',
    }),
    created() {
        if (!this.emailAddressProp || !this.codeProp) {
            this.errorMessage = 'Something went wrong with the link, please try again.';
            this.errorState = true;
        }
        else {
            this.username = this.emailAddressProp;
        }
    },
    watch: {
        username: function () {
            this.username = _.trim(this.username);
        },
    },
    methods: {
        async onSubmit(event) {
            event.preventDefault();
            this.errorMessage = '';

            if (this.newPassword !== this.confirmPassword) {
                this.errorMessage = 'New password and Confirm new password must match.';
            }
            else {
                this.loading = true;
                let result = await authService.forgotPasswordConfirm(
                    new AuthenticateForgotPasswordConfirm(
                        this.emailAddressProp,
                        this.emailAddressProp,
                        this.newPassword,
                        this.codeProp,
                    ),
                );

                if (result.status === 200) {
                    this.passwordUpdated = true;
                }
                else {
                    let validationMessages = [];
                    _.mapKeys(result.data.User, function (value) {
                        validationMessages.push(`<span>${value}</span>`);
                    });
                    let validationMessageSummary = `<div>${_.map(validationMessages).join(
                        '',
                    )}</div>`;
                    this.errorMessage = validationMessageSummary;
                }
            }

            this.loading = false;
        },
    },
};
</script>

<style scoped>
@import "../../assets/scss/vuetify-elements/card.scss";

.smc-card-dialog {
  margin-top: 100px;
}
</style>
