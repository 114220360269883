<template>
    <v-card width="600" class="mx-auto smc-card-dialog">
        <v-card-title
            class="
        primary
        white--text
        text-center
        smc-card-dialog-title smc-card-dialog-title-narrow
      "
        >
            <span class="mx-auto">CONFIRM EMAIL</span>
        </v-card-title>
        <v-expand-transition>
            <div class="text-center pa-6" v-if="loading && !emailConfirmSuccess">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                    class="mr-4"
                />
                <strong>Loading...</strong>
            </div>
        </v-expand-transition>
        <v-expand-transition>
            <div v-if="tokenInvalid">
                <p>
                    It seems that your email confirmation token has expired. Please click
                    on the following button for a new token to be emailed to your email
                    address.
                </p>
                <v-btn
                    color="primary"
                    rounded
                    @click="resendEmailConfirmation"
                    :disabled="loading || resendEmailConfirmSuccess"
                >
                    Resend confirmation email
                </v-btn>
                <p class="mt-3">
                    The email confrimation link will be valid for 24 hours.
                </p>
                <v-alert color="success" v-if="resendEmailConfirmSuccess">
                    The new email confirmation has been sent to yor email address.
                </v-alert>
            </div>
        </v-expand-transition>
        <v-expand-transition>
            <div class="py-6" v-if="emailConfirmSuccess">
                <p>Your email has been successfully confirmed.</p>
                <p>
                    You can now proceed to
                    <v-btn small rounded color="primary" to="/account/login">
                        login
                    </v-btn>
                </p>
            </div>
        </v-expand-transition>
        <v-expand-transition>
            <div v-if="errorMessage !== ''" class="pa-6">
                <v-alert border="left" color="error" outlined rounded="">
                    <span v-html="errorMessage"></span>
                </v-alert>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
import _ from 'lodash';
import { authService } from '@/services';

export default {
    name: 'confirm-email',
    props: {
        paramsProp: String,
    },
    data: () => ({
        loading: false,
        emailConfirmSuccess: false,
        resendEmailConfirmSuccess: false,
        tokenInvalid: false,
        errorMessage: '',
    }),
    async created() {
        this.loading = true;
        let result = await authService.confirmEmail({
            authParams: this.paramsProp,
        });
        if (result.status === 200) {
            this.emailConfirmSuccess = true;
        }
        else {
            if (result.data.User) {
                if (result.data.User[0] === 'Invalid token.') {
                    this.tokenInvalid = true;
                }
                let validationMessages = [];
                _.mapKeys(result.data.User, function (value) {
                    validationMessages.push(`<li>${value}</li>`);
                });
                let validationMessageSummary = `<ul>${_.map(validationMessages).join(
                    '',
                )}</ul>`;
                this.errorMessage = validationMessageSummary;
            }
            else {
                this.errorMessage = 'Something has gone wrong.';
            }
        }
        this.loading = false;
    },
    methods: {
        async resendEmailConfirmation() {
            this.errorMessage = '';

            this.loading = true;
            let result = await authService.resendEmailConfirmation({
                authParams: this.paramsProp,
            });
            if (result.status === 200) {
                this.resendEmailConfirmSuccess = true;
            }
            else {
                if (result.response.data.User) {
                    let validationMessages = [];
                    _.mapKeys(result.response.data.User, function (value) {
                        validationMessages.push(`<li>${value}</li>`);
                    });
                    let validationMessageSummary = `<ul>${_.map(validationMessages).join(
                        '',
                    )}</ul>`;
                    this.errorMessage = validationMessageSummary;
                }
                else {
                    this.errorMessage = 'Something has gone wrong.';
                }
            }
            this.loading = false;
        },
    },
};
</script>

<style scoped>
@import "../../assets/scss/vuetify-elements/card.scss";

.smc-card-dialog {
  margin-top: 100px;
}
</style>
